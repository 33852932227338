<template>
    <div class="mainWrap">
        <!-- <frontslider table="emt_settings" app="emt" ref="slidercomp" style="display:block;"></frontslider> -->


        <div id="logins">

            <link href="https://fonts.googleapis.com/css?family=Roboto:100,400,800" rel="stylesheet" />

            <div class="fs-container">
                <div class="fs-container__bg"></div>
                <div class="content">
                    <div class="content-login">
                        <div class="content__pane init2">

                            <img class="logo" src="@/assets/emt-logo-small.png" style="max-width: 60px" />

                            <form name="login" id="login-form" novalidate="" method="POST" v-on:submit.prevent
                                style="max-width: 286px;">
                                <div class="flex-container">
                                    <input type="hidden" id="t_login" name="t_login" value="NO_QRCODE" />

                                    <div id="classic-login">
                                        <div class="group filled">
                                            <input v-model="input.username" id="user" class="user" required=""
                                                name="user" type="text" value="" />

                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>
                                                <img src="@/assets/loginstart/img/user.png" />
                                                <span class="label__text label__text--hidden">Username</span>
                                            </label>
                                        </div>

                                        <div class="group filled" style="margin-bottom: 0px">
                                            <input ref="currPass" v-model="input.password" id="password"
                                                class="password" required="" name="password" type="password" value="" />
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <img @click="showPassword" class="showpwd"
                                                src="@/assets/icons8-eye-24.png" />

                                            <label>
                                                <span class="label__text label__text--hidden">Password</span>
                                                <img src="@/assets/loginstart/img/pwd.png" />
                                            </label>

                                            <a id="forgotPass" @click="recover">
                                                Forgot password?
                                            </a>


                                        </div>
                                    </div>

                                    <div id="qr-code-wrapper">
                                        <button type="button" id="qr-code-btn">
                                            <span class="tooltiptext">Login with QR-Code</span>

                                            <img src="@/assets/loginstart/img/qr-btn-alpha.png" alt="submit" />
                                        </button>
                                    </div>

                                    <!-- added code -->

                                    <div class="container" id="qr-login" style="display: none">
                                        <div>
                                            <a class="button" id="resetButton">Back</a>
                                        </div>

                                        <div>
                                            <video id="video" width="300" height="200"
                                                style="border: 1px solid gray"></video>
                                        </div>

                                        <div id="sourceSelectPanel" style="display: none">
                                            <label for="sourceSelect">Change video source:</label>
                                            <select id="sourceSelect" style="max-width: 400px"></select>
                                        </div>

                                        <div style="text-align: left; display: none">
                                            <label>Result:</label>
                                            <pre><code id="result"></code></pre>
                                        </div>
                                    </div>

                                    <!-- end added code -->
                                </div>

                                <div id="lang-select" class="group select">
                                    <div class="lang__field">
                                        <span class="lang__selected"><img src="@/assets/loginstart/img/uk.png"
                                                class="flag-img" /></span>
                                        <label class="select__label">
                                            <img src="@/assets/loginstart/img/language.png" />
                                        </label>
                                        <div class="lang__options">
                                            <div data-value="EN" class="lang__option">
                                                <img src="@/assets/loginstart/img/uk.png" class="flag-img" />
                                            </div>
                                            <div data-value="IT" class="lang__option">
                                                <img src="@/assets/loginstart/img/italy.png" class="flag-img" />
                                            </div>
                                        </div>
                                    </div>
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                </div>

                                <div id="otp-select" class="group select">
                                    <div class="otp__field">
                                        <span class="otp__selected">
                                            <div class="otp-text">Email</div>
                                            <img src="@/assets/loginstart/img/mail.png" class="otp-img" />
                                        </span>
                                        <label class="select__label">
                                            <div class="text-label">OTP</div>
                                            <img src="@/assets/loginstart/img/token.png" />
                                        </label>
                                        <div class="otp__options">
                                            <div data-value="MAIL" class="otp__option">
                                                <div class="otp-text">Email</div>
                                                <img src="@/assets/loginstart/img/mail.png" class="otp-img" />
                                            </div>
                                            <div data-value="SMS" class="otp__option">
                                                <div class="otp-text">Sms</div>
                                                <img src="@/assets/loginstart/img/sms.png" class="otp-img" />
                                            </div>

                                            <div data-value="Auth" class="otp__option">
                                                <div class="otp-text">Auth</div>
                                                <img src="@/assets/loginstart/img/auth.png" class="otp-img" />
                                            </div>
                                        </div>
                                    </div>
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                </div>

                                <button class="
                              button button--block button--outline button--primary
                              modal-trigger
                            " id="login-button" value="login" v-on:click="login()">
                                    <div class="button--text">Login</div>
                                    <div class="spinner hidden">
                                        <div class="holder">
                                            <div class="preloader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </button>

                                <rise-loader v-if="showSpinner" :size="'20px'" :color="'#0000b9'" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </div>


        <div class="contpopupotplogin" style="display:none">

            <template>
                <modal name="popupOtpLogin" :clickToClose="false" :width="262" :height="221">


                    <v-row>

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;font-size:12px;text-align: center;">

                            {{ titoloPopupOtp }}

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="text" v-model="valueOtpLogin" label="" id="fldValueOtpLoginInvoice"
                                onkeydown="if (!/^[0-9]$/.test(event.key) && event.key !== 'Backspace') event.preventDefault();">
                            </v-text-field>

                        </v-col>

                    </v-row>

                    <v-row v-if="showResend">

                        <v-col cols="12" md="12" style="display: flex;
                        justify-content: center;
                        padding-top: 0px!important;
                        margin-top: 0px!important;
                        font-size:12px">

                            <div style="font-weight: bold;
                        cursor: pointer;" class="clReinviaOtp">Reinvia</div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-login" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelOtpLogin" />

                                <v-img alt="" class="btn-confirm-otp-login" contain
                                    src="@/assets/btn_confirm3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmOtpLogin" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupqrgoogle" style="display:none">

            <template>
                <modal name="popupQRGoogle" :clickToClose="false" :width="344" :height="329">


                    <v-row>

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;font-size:12px;text-align: center;">

                            Scansiona con Google Authenticator il QR Code

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="display: flex;justify-content: center;">

                            <canvas ref="qrcode"></canvas>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-qrcode-google" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelQRCodeGoogle" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupotpgoogle" style="display:none">

            <template>
                <modal name="popupOtpGoogle" :clickToClose="false" :width="337" :height="317">

                    <v-row style="margin-bottom: 0px!important;padding-bottom: 0px!important;">

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;font-size:12px;text-align: center;display:flex;justify-content: center;margin-bottom: 0px!important;padding-bottom: 0px!important;">

                            <img class="logo" src="@/assets/logo_auth.png" style="max-width: 40px" />


                        </v-col>

                    </v-row>

                    <v-row style="margin-top: 0px!important;padding-top: 0px!important">

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;font-size:12px;text-align: center;display:flex;justify-content: center;">

                            <div style="width: 310px;">
                                Apri l'APP Authenticator del tuo smartphone e genera il codice OTP.
                                Dura 30 secondi, attendi una nuova generazione prima di inserirlo

                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="text" v-model="valueOtpLogin" label="" id="fldValueOtpLoginInvoice"
                                onkeydown="if (!/^[0-9]$/.test(event.key) && event.key !== 'Backspace') event.preventDefault();">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-google" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelOtpGoogle" />

                                <v-img alt="" class="btn-confirm-otp-google" contain
                                    src="@/assets/btn_confirm3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmOtpGoogle" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



    </div>
</template>

<style lang="css" scoped>
@import "./../assets/loginstart/style.css";
</style>

<script>
import $ from 'jquery';
import RiseLoader from "vue-spinner/src/RiseLoader.vue";
import router from ".././router";
import QRCode from 'qrcode';

export default {

    components: {
        /* PulseLoader, */
        RiseLoader
    },

    updated() { },

    mounted: function () {

        var pointerVue = this;

        $('#addedCSS').text("");

        var loggedIn = this.$cookies.get('token');

        if ((location.hostname == "testheyjob.emtool.eu") || (location.hostname == "presenze.stipendio.net")) {
            this.showEmt = false;
            this.showNext3 = true;
        }



        if (loggedIn) {
            router.push({
                path: "/dash"
            });
        }
        console.log("Ciao: " + this.userLogged);



        var v_domain = this.getUrlDomainSimple("");


        if (v_domain == "https://app.emtool.eu/") {

            this.showEmt = true;
            this.showLC = false;
            this.showAriall = false;
            this.showAgila = false;

        }

        if (v_domain == "https://app.lc-service.it/") {

            this.showEmt = false;
            this.showLC = true;
            this.showAriall = false;
            this.showAgila = false;

        }

        if (v_domain == "https://app.ariall.eu/") {

            this.showEmt = false;
            this.showLC = false;
            this.showAriall = true;
            this.showAgila = false;

        }

        if (v_domain == "https://agila.emtool.eu/") {

            this.showEmt = false;
            this.showLC = false;
            this.showAriall = false;
            this.showAgila = true;

        }

        document.title = "login";

        $(document).ready(function () {


            $('body').on('click', '.clReinviaOtp', function (event) {
                event.preventDefault();

                pointerVue.showResend = false;

                pointerVue.$modal.hide('popupOtpLogin');


                pointerVue.resendOtp(pointerVue.input.username);


            });



        });


    },

    name: "Login",

    data() {
        return {

            titoloPopupOtp: "",

            valueOtpLogin: "",

            showResend: false,


            valueOtpDelete: "",

            dialogRegister: true,

            showEmt: true,
            showNext3: false,

            showLC: false,
            showAriall: false,

            showAgila: false,

            notifications: false,
            sound: true,
            widgets: false,

            showSpinner: false,
            input: {
                username: "",
                password: "",
            },
        };
    },
    methods: {

        btnCancelOtpGoogle: function () {

            this.$modal.hide('popupOtpGoogle');


        },

        loginAction3: async function () {

            var that = this;

            that.showSpinner = true;

            const url2 = this.getUrlDomain("verifyTwoFactorAuth");

            let formData6 = new FormData();
            formData6.append("username", btoa(that.input.username));
            formData6.append("val_code", that.valueOtpLogin);

            await this.axios
                .post(url2, formData6, {
                    "content-type": "application/json"
                })
                .then((result6) => {

                    that.showSpinner = false;

                    console.log("RESULT6: ", result6);

                    if (result6.data.Result == "OK") {

                        that.$modal.hide('popupOtpGoogle');


                        that.loginAction2(that.input.username);

                    }
                    else {

                        that.valueOtpLogin = "";

                        that.$swal({
                            icon: "error",
                            text: result6.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 3000
                        });

                        setTimeout(() => {

                            $("#fldValueOtpLoginInvoice").focus();

                        }, 300);


                    }



                });

        },

        btnConfirmOtpGoogle: async function () {

            if (this.valueOtpLogin != "") {

                this.loginAction3();


            }
            else {


                $("#fldValueOtpLoginInvoice").focus();

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore del codice",
                    showConfirmButton: false,
                    timer: 2000
                });

            }

        },



        btnCancelQRCodeGoogle: function () {

            this.$modal.hide('popupQRGoogle');


            setTimeout(() => {


                $(".contpopupotpgoogle").show();

                this.$modal.show('popupOtpGoogle');

                setTimeout(() => {

                    $("#fldValueOtpLoginInvoice").focus();

                }, 300);


            }, 200);


        },


        resendOtp: async function (v_username) {


            if (window.typeLogin == "OTP") {

                this.showSpinner = true;

                const url2 = this.getUrlDomain("generateOtpAndSendMail");

                let formData2 = new FormData();
                formData2.append("username", btoa(v_username));
                formData2.append("name_product", "emt");
                formData2.append("tipo_op", "login");
                formData2.append("v_id", 0);

                await this.axios
                    .post(url2, formData2, {
                        "content-type": "application/json"
                    })
                    .then((result2) => {

                        this.showSpinner = false;

                        console.log("RESULT2: ", result2);

                        window.myVal = result2.data.Result;


                        setTimeout(() => {

                            this.showResend = true;

                            this.valueOtpLogin = "";

                            this.titoloPopupOtp = "Codice OTP inviatoLe via mail";

                            $(".contpopupotplogin").show();

                            this.$modal.show('popupOtpLogin');

                            setTimeout(() => {

                                $("#fldValueOtpLoginInvoice").focus();

                            }, 300);


                        }, 200);


                    });

            }

            if (window.typeLogin == "SMS") {

                this.showSpinner = true;

                const url2 = this.getUrlDomain("generateOtpAndSendSMS");

                let formData3 = new FormData();
                formData3.append("username", btoa(this.input.username));
                formData3.append("name_product", "invoice");
                formData3.append("mobile", window.mobile);
                formData3.append("prefix_mobile", window.prefix_mobile);

                await this.axios
                    .post(url2, formData3, {
                        "content-type": "application/json"
                    })
                    .then((result3) => {

                        this.showSpinner = false;

                        console.log("RESULT2: ", result3);


                        if (result3.data.Result == "OK") {



                            window.myVal = result3.data.ValReturn;


                            setTimeout(() => {

                                this.showResend = true;

                                this.valueOtpLogin = "";

                                this.titoloPopupOtp = "Codice OTP inviatoLe via SMS";


                                $(".contpopupotplogin").show();

                                this.$modal.show('popupOtpLogin');

                                setTimeout(() => {

                                    $("#fldValueOtpLoginInvoice").focus();

                                }, 300);


                            }, 200);


                        }
                        else {

                            this.$swal({
                                icon: "error",
                                text: result3.data.ErrDetails,
                                showConfirmButton: false,
                                timer: 3000
                            });

                        }







                    });

            }







        },



        btnCancelOtpLogin: function () {

            this.$modal.hide('popupOtpLogin');


        },

        btnConfirmOtpLogin: function () {


            if (this.valueOtpLogin != "") {

                console.log("OTP INP: ", this.valueOtpLogin);
                console.log("ENC OTP INP: ", btoa(this.valueOtpLogin));

                if (window.myVal == btoa(this.valueOtpLogin)) {

                    this.$modal.hide('popupOtpLogin');


                    this.loginAction2(this.input.username);


                }
                else {


                    this.$swal({
                        icon: "error",
                        text: "Valore OTP non corretto",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        this.valueOtpLogin = "";
                        $("#fldValueOtpLoginInvoice").focus();

                    }, 2500);


                }



            }
            else {


                $("#fldValueOtpLoginInvoice").focus();

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },



        removeFavicon() {
            // Trova tutti gli elementi <link> che hanno rel="icon"
            const favicons = document.querySelectorAll('link[rel="icon"], link[rel="shortcut icon"], link[rel="apple-touch-icon"]');

            // Rimuovi ogni favicon trovata
            favicons.forEach(favicon => favicon.parentNode.removeChild(favicon));
        },

        getUrlDomainSimple: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },



        getDomain: function () {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result;

        },



        redirecta() {


            var settSmartbook = JSON.parse(window.$cookies.get("settInvoiceLogin"));
            console.log("settSmartbook", settSmartbook[0]);
            var settings = settSmartbook[0];

            if (settings != null) {


                var backgroundImage = settings.backgroundImage;

                var txt_image = this.getUrlDomainSimple("public/documents/invoice/assets/" + backgroundImage);


                var rule = ` .bkc_over_image {background-image :url(` + txt_image + `)!important}`;


                $('#addedCSS').text(rule);


            }

            setTimeout(() => {
                router.push({
                    path: "/dash"
                });
            }, 300);

        },




        getUrlLoginApi: function () {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/utility/checkLogin.php";

        },


        showPassword() {

            var currPass = this.$refs.currPass;
            if (currPass.type === "password") {
                currPass.type = "text"
                currPass.btnText = "Hide Password"
            } else {
                currPass.type = "password"
                currPass.btnText = "Show Password"
            }
        },



        recover: function () {
            // this.showSpinner = true;



            var spinner = $("#bspinner");
            var loginButton = $("#login-button");

            var API_URL = this.getUrlDomain("resetPwdEmtApp");

            var that = this;

            this.$swal
                .fire({
                    title: "Password recovery",
                    html: "Type your username",
                    /*  icon: "warning", */
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_invoiceweb.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_invoiceweb.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    },

                    input: "text",

                    inputAttributes: {
                        autocapitalize: "off",
                    },

                    preConfirm: (username) => {
                        // console.log(username);

                        // show spinner && disable button
                        spinner.removeClass("vhidden");
                        that.showSpinner = true;

                        loginButton.prop("disabled", true);

                        var body = {
                            user: username,
                            method: "SEND_OLD_PASSWORD",
                            site_domain: "https://app.emtool.eu/",
                            product: 'Emt'
                        };

                        console.log(body);
                        // call the service
                        $.ajax({
                            type: "POST",
                            url: API_URL,
                            data: body,
                            success: function (resultData) {

                                console.log("RESULTDATA: ", resultData);

                                // hide spinner && enable button
                                spinner.addClass("vhidden");
                                that.showSpinner = false;

                                loginButton.prop("disabled", false);

                                if (resultData.Result == "OK") {
                                    var message;

                                    message = "Check your email";

                                    //open ok modal


                                    that.$swal.fire({
                                        icon: "success",
                                        title: message,
                                        showConfirmButton: false,
                                        timer: 2000
                                    });
                                } else {
                                    var errorMessage;

                                    switch (resultData.Result) {
                                        case "USER_NOT_FOUND":
                                            errorMessage = "Utente non trovato";
                                            break;
                                        default:
                                            errorMessage = "Utente non trovato";
                                    }

                                    that.$swal.fire({
                                        title: "Errore",
                                        text: errorMessage,
                                        icon: "error",
                                        showConfirmButton: false,
                                        timer: 2000
                                    });

                                    that.showSpinner = false;
                                }



                            },
                            error: function () {
                                // hide spinner && enable login button
                                spinner.addClass("vhidden");
                                loginButton.prop("disabled", false);
                                that.showSpinner = false;
                                //  errorHandler(event);
                            },
                        });
                    },

                });


            setTimeout(() => {


                $(".swal2-input").val(that.input.username);


            }, 500);





        },

        getCookie() {
            this.$cookies.set("cookie-consent-performance", "yes");

            //  this.$swal('You are logged in!!!');

            //   console.log(Vue.cookie.get('token'));

            // it gets the cookie called `username`
            /* const username = this.$cookies.get("username");
            console.log(username); */
        },

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "api/" + nome_metodo;

        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },


        loginAction2: async function (v_username) {

            const url2 = this.getUrlDomainApi("pswUserIsChanged");

            let formData2 = new FormData();
            formData2.append("username", v_username);

            await this.axios
                .post(url2, formData2, {
                    "content-type": "application/json"
                })
                .then((result2) => {

                    this.showSpinner = false;

                    console.log("RESULT2: ", result2);

                    setTimeout(() => {

                        window.$cookies.set("is_pwd_changed_once", result2.data.IsChanged, "9y");

                    }, 1);

                    if (result2.data.IsChanged == 0) {


                        setTimeout(() => {
                            router.push({
                                path: "/changepasswordinitial"
                            });

                        }, 300);




                    }

                    if (result2.data.IsChanged == 1) {

                        setTimeout(() => {


                            var uname = v_username;
                            const args = [uname, true, ['bar', 5], {
                                foo: 'baz'
                            }];
                            try {
                                console.log("entering user");
                                window.flutter_inappwebview.callHandler('setLoggedUser', ...args);

                            } catch (error) {
                                console.log(error);
                            }

                            this.$root.$children[0].curDipTop = window.namesurname;

                            this.$root.$children[0].changeMenu();
                            //Load companies array
                            this.$root.$children[0].syncMonth();
                            this.$root.$children[0].syncGroups();

                            this.$root.$children[0].refreshMenu();

                            this.$root.$children[0].registerLogin();

                            this.$cookies.set("last_menu", "/messages");


                            this.redirecta();












                        }, 1);


                    }



                });

        },


        loginAction: async function () {
            //const auth = { user: this.username, password: this.password };
            // Correct username is 'foo' and password is 'bar'
            const url = this.getUrlLoginApi();



            this.success = false;
            this.error = null;

            let formData = new FormData();


            formData.append("user", this.input.username);
            formData.append("password", this.input.password);
            formData.append("productid", "invoice");

            var login = this.input.username;
            try {
                await this.axios
                    .post(url, formData, {
                        "content-type": "application/json"
                    })
                    .then(async (result) => {
                        var respo = result.data;
                        this.success = true;
                        console.log("RESPO: ", respo);
                        console.log("LOGIN: ", login);

                        window.v_respo = respo;

                        if (respo.Result == "OK") {

                            if (respo.product_enable == 1) { // prodotto abilitato

                                setTimeout(() => {

                                    window.namesurname = respo.namesurname;
                                    window.typeLogin = respo.typeLogin;
                                    window.mobile = respo.mobile;
                                    window.prefix_mobile = respo.prefix_mobile;

                                    window.namesurname = respo.namesurname;

                                    window.$cookies.set("a", respo.a, "9y");
                                    window.$cookies.set("b", respo.b, "9y");
                                    window.$cookies.set("token", respo.token, "9y");
                                    window.$cookies.set("system", respo.system, "9y");
                                    window.$cookies.set("token_system", respo.token_system, "9y");
                                    window.$cookies.set("username", respo.username, "9y");
                                    window.$cookies.set("user_id", respo.user_id, "9y");
                                    window.$cookies.set("namesurname", respo.namesurname, "9y");
                                    window.$cookies.set("is_ebadge", respo.is_ebadge, "9y");
                                    window.$cookies.set("is_agila", respo.is_agila, "9y");
                                    window.$cookies.set("models", respo.models, "9y");
                                    window.$cookies.set("product_permissions", respo.product_permissions, "9y");
                                    window.$cookies.set("azienda", respo.cid, "9y");


                                    window.$cookies.set("settInvoiceLogin", JSON.stringify(respo.settProduct), "9y");


                                }, 1);


                                try { // verifico se è la prima volta che accede gli chiedo cambio pwd


                                    if (respo.typeLogin == "OTP") {

                                        this.showSpinner = true;

                                        const url2 = this.getUrlDomain("generateOtpAndSendMail");

                                        let formData2 = new FormData();
                                        formData2.append("username", btoa(this.input.username));
                                        formData2.append("name_product", "emt");
                                        formData2.append("tipo_op", "login");
                                        formData2.append("v_id", 0);

                                        await this.axios
                                            .post(url2, formData2, {
                                                "content-type": "application/json"
                                            })
                                            .then((result2) => {

                                                this.showSpinner = false;

                                                console.log("RESULT2: ", result2);

                                                window.myVal = result2.data.Result;


                                                setTimeout(() => {

                                                    this.showResend = true;

                                                    this.valueOtpLogin = "";

                                                    this.titoloPopupOtp = "Codice OTP inviatoLe via mail";

                                                    $(".contpopupotplogin").show();

                                                    this.$modal.show('popupOtpLogin');

                                                    setTimeout(() => {

                                                        $("#fldValueOtpLoginInvoice").focus();

                                                    }, 300);


                                                }, 200);

                                            });

                                    }




                                    if (respo.typeLogin == "SMS") {

                                        this.showSpinner = true;

                                        const url2 = this.getUrlDomain("generateOtpAndSendSMS");

                                        let formData3 = new FormData();
                                        formData3.append("username", btoa(this.input.username));
                                        formData3.append("name_product", "emt");
                                        formData3.append("mobile", respo.mobile);
                                        formData3.append("prefix_mobile", respo.prefix_mobile);

                                        await this.axios
                                            .post(url2, formData3, {
                                                "content-type": "application/json"
                                            })
                                            .then((result3) => {

                                                this.showSpinner = false;

                                                console.log("RESULT2: ", result3);


                                                if (result3.data.Result == "OK") {



                                                    window.myVal = result3.data.ValReturn;


                                                    setTimeout(() => {

                                                        this.showResend = true;

                                                        this.valueOtpLogin = "";

                                                        this.titoloPopupOtp = "Codice OTP inviatoLe via SMS";


                                                        $(".contpopupotplogin").show();

                                                        this.$modal.show('popupOtpLogin');

                                                        setTimeout(() => {

                                                            $("#fldValueOtpLoginInvoice").focus();

                                                        }, 300);


                                                    }, 200);


                                                }
                                                else {

                                                    this.$swal({
                                                        icon: "error",
                                                        text: result3.data.ErrDetails,
                                                        showConfirmButton: false,
                                                        timer: 3000
                                                    });

                                                }







                                            });

                                    }



                                    if (respo.typeLogin == "Authenticator") {

                                        this.showSpinner = true;

                                        const url2 = this.getUrlDomain("enableTwoFactorAuth");

                                        let formData4 = new FormData();
                                        formData4.append("username", btoa(this.input.username));

                                        await this.axios
                                            .post(url2, formData4, {
                                                "content-type": "application/json"
                                            })
                                            .then((result4) => {

                                                this.showSpinner = false;

                                                console.log("RESULT2: ", result4);


                                                if (result4.data.Result == "OK") {

                                                    if (result4.data.view_qrcode == 1) {

                                                        setTimeout(() => {


                                                            $(".contpopupqrgoogle").show();

                                                            this.$modal.show('popupQRGoogle');

                                                            setTimeout(() => {

                                                                QRCode.toCanvas(this.$refs.qrcode, result4.data.qrCodeUrl, function (error) {
                                                                    if (error) console.error(error);
                                                                });

                                                            }, 300);


                                                        }, 200);

                                                    }



                                                    if (result4.data.view_qrcode == 0) {

                                                        setTimeout(() => {


                                                            $(".contpopupotpgoogle").show();

                                                            this.$modal.show('popupOtpGoogle');

                                                            this.valueOtpLogin = "";

                                                            setTimeout(() => {

                                                                $("#fldValueOtpLoginInvoice").focus();

                                                            }, 300);


                                                        }, 200);

                                                    }




                                                }
                                                else {

                                                    this.$swal({
                                                        icon: "error",
                                                        text: result4.data.ErrDetails,
                                                        showConfirmButton: false,
                                                        timer: 3000
                                                    });

                                                }







                                            });

                                    }





                                    if (respo.typeLogin == "SMS_ERR_TEL_NUMBER") {

                                        this.showSpinner = false;

                                        this.$swal({
                                            icon: "error",
                                            text: "Numero di Telefono non impostato correttamente, chiedere all'amministratore",
                                            showConfirmButton: false,
                                            timer: 4000
                                        });


                                    }




                                    if (respo.typeLogin == "") {

                                        const url2 = this.getUrlDomainApi("pswUserIsChanged");

                                        let formData2 = new FormData();
                                        formData2.append("username", this.input.username);

                                        await this.axios
                                            .post(url2, formData2, {
                                                "content-type": "application/json"
                                            })
                                            .then((result2) => {

                                                this.showSpinner = false;

                                                console.log("RESULT2: ", result2);

                                                setTimeout(() => {

                                                    window.$cookies.set("is_pwd_changed_once", result2.data.IsChanged, "9y");

                                                }, 1);

                                                if (result2.data.IsChanged == 0) {


                                                    setTimeout(() => {
                                                        router.push({
                                                            path: "/changepasswordinitial"
                                                        });

                                                    }, 300);




                                                }

                                                if (result2.data.IsChanged == 1) {

                                                    setTimeout(() => {


                                                        var uname = login.replace("@", "_");
                                                        const args = [uname, true, ['bar', 5], {
                                                            foo: 'baz'
                                                        }];
                                                        try {
                                                            console.log("entering user");
                                                            window.flutter_inappwebview.callHandler('setLoggedUser', ...args);

                                                        } catch (error) {
                                                            // alert(error);
                                                        }

                                                        this.$root.$children[0].curTitle = respo.namesurname;

                                                        //update menu after login
                                                        this.$root.$children[0].changeMenu();
                                                        //Load companies array
                                                        this.$root.$children[0].syncMonth();
                                                        this.$root.$children[0].syncGroups();

                                                        this.$root.$children[0].refreshMenu();

                                                        this.$root.$children[0].registerLogin();

                                                        this.$cookies.set("last_menu", "/messages");


                                                        this.redirecta();












                                                    }, 1);


                                                }



                                            });



                                    }





                                }
                                catch (err2) {
                                    this.success = false;
                                    console.log(err2.message);
                                    this.$swal({
                                        icon: "error",
                                        text: "Errore durante la login"
                                    });

                                    this.showSpinner = false;
                                }


                            }
                            else {

                                this.$swal({
                                    icon: "error",
                                    text: "Prodotto non abilitato",
                                    showConfirmButton: false,
                                    timer: 3000
                                });

                                this.showSpinner = false;


                            }

                        }
                        else {

                            this.success = false;
                            this.$swal({
                                icon: "error",
                                text: respo.ErrDetails,
                                confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_invoiceweb.png' />",
                                cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_invoiceweb.png' />",
                                showCancelButton: false,
                                customClass: {
                                    cancelButton: 'order-1',
                                    confirmButton: 'order-2',
                                }
                            });

                            this.showSpinner = false;




                        }









                    });
            } catch (err) {
                this.success = false;
                console.log(err.message);
                this.$swal({
                    icon: "error",
                    text: "Errore durante l'accesso",
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_invoiceweb.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_invoiceweb.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                });

                this.showSpinner = false;

            }
        },

        login() {
            this.showSpinner = true;
            this.getCookie();
            this.loginAction();
        },
    },
};
</script>

<style>
#fldValueOtpLoginInvoice {
    text-align: center;
    font-size: 14px;
}
</style>

<style scoped>
#qr-code-wrapper,
#lang-select,
#otp-select {
    display: none;
}

#login {
    width: auto;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    margin: auto;
    /*   margin-top: 200px; */
    padding: 20px;
}

#nav,
.v-sheet {
    display: none;
}

img.showpwd {
    position: absolute;
    /* right: 0px; */
    top: 14px;
    right: 0px;
    max-width: 22px;
    cursor: pointer;

}
</style>